import { ICache, MemoryCache, NoCache } from '@halo-data/utilities';
import {
  AssetKind,
  AssetKindTypeMap,
  AssetVersionLink,
  MatchType,
  PlayerMatchHistory,
  UserInfo,
} from 'halo-infinite-api';
import { compareXuids, unwrapXuid } from '../../../libs/utilities/src/xuids';
import { haloInfiniteClient, xboxClient } from './clients';
import { nextRedirectRejectionHandler } from './match-query/promise-helpers';
import requestRetryPolicy from './match-query/request-policy';
const gamerTagCache = new MemoryCache({
  maxEntries: 1,
  rollingExpiration: true,
  keyTransformer: (gamertag: string) => gamertag.toLowerCase(),
  fetchOneFn: async (gamertag: string, signal) => {
    const result = await requestRetryPolicy.execute(
      (ctx) => haloInfiniteClient.getUser(gamertag, { signal: ctx.signal }),
      signal
    );

    if (result.gamertag.toLowerCase() !== gamertag.toLowerCase()) {
      // 343 has returned the wrong gt. Cool, let's hack around that.
      const searchResults = await xboxClient.searchUsers(gamertag, { signal });
      const searchResult = searchResults.find(
        (r) => r.gamertag.toLowerCase() === gamertag.toLowerCase()
      );
      if (!searchResult) {
        throw new Error(`Failed to find user ${gamertag}`);
      }
      return xuidCache.get(searchResult.id, signal);
    }

    xuidCache.set(result.xuid, result);
    return result;
  },
});
const xuidCache = new MemoryCache({
  maxEntries: 1000,
  rollingExpiration: true,
  keyTransformer: (xuid: string) => unwrapXuid(xuid),
  fetchManyFn: (xuids: string[], signal) =>
    requestRetryPolicy.execute(
      (ctx) => haloInfiniteClient.getUsers(xuids, { signal: ctx.signal }),
      signal
    ),
  resultSelector: (items, xuid) => {
    const item = items.find((i) => compareXuids(i.xuid, xuid));
    if (!item) {
      throw new Error(`Failed to find user ${xuid}`);
    }
    gamerTagCache.set(item.gamertag, item);
    return item;
  },
});
class CombinedUserCache implements ICache<UserInfo, string, []> {
  get(key: string, signal?: AbortSignal): Promise<UserInfo>;
  get(keys: string[], signal?: AbortSignal): Promise<UserInfo>[];
  get(
    keyOrKeys: string | string[],
    signal?: AbortSignal
  ): Promise<UserInfo> | Promise<UserInfo>[] {
    if (Array.isArray(keyOrKeys)) {
      const xuids: string[] = [];
      const gamertags: string[] = [];
      keyOrKeys.forEach((x) => {
        if (/^xuid\(\d+\)$/i.test(x)) {
          xuids.push(x);
        } else {
          gamertags.push(x);
        }
      });
      return [
        ...gamerTagCache.get(gamertags, signal),
        ...xuidCache.get(xuids, signal),
      ];
    } else {
      if (/^xuid\(\d+\)$/i.test(keyOrKeys)) {
        return xuidCache.get(keyOrKeys, signal);
      } else {
        return gamerTagCache.get(keyOrKeys, signal);
      }
    }
  }
  set(_key: string, value: UserInfo): void {
    gamerTagCache.set(value.gamertag, value);
    xuidCache.set(value.xuid, value);
  }
  delete(key: string): void {
    gamerTagCache.delete(key);
    xuidCache.delete(key);
  }
}
export const usersCache: ICache<UserInfo, string, []> = new CombinedUserCache();
export const matchStatsCache = new NoCache({
  fetchOneFn: (matchId: string, signal) =>
    requestRetryPolicy.execute(
      (ctx) =>
        haloInfiniteClient.getMatchStats(matchId, { signal: ctx.signal }),
      signal
    ),
});
export const matchSkillsCache = new MemoryCache({
  maxEntries: 8000,
  rollingExpiration: true,
  async fetchManyFn(keys: { matchId: string; playerId: string }[], signal) {
    const matchGroups = keys.groupBy((k) => k.matchId);
    const results = await Promise.allSettled(
      Array.from(matchGroups.entries())
        .filter(([_, group]) => group.length > 0)
        .map(async ([matchId, group]) => {
          return {
            matchId,
            skills: await requestRetryPolicy.execute(
              (ctx) =>
                haloInfiniteClient.getMatchSkill(
                  matchId,
                  group.map((p) => p.playerId),
                  { signal: ctx.signal }
                ),
              signal
            ),
          };
        })
    ).then(nextRedirectRejectionHandler);
    return results;
  },
  resultSelector: (results, key) => {
    const result = results.find((r) => r.matchId === key.matchId);
    if (!result) {
      throw new Error(`Failed to find match ${key.matchId}`);
    }
    const skill = result.skills.find((s) => s.Id === key.playerId);
    if (!skill) {
      throw new Error(`Failed to find skill ${key.matchId}.${key.playerId}`);
    }
    return skill;
  },
  keyTransformer: (key) => `${key.matchId}.${key.playerId}`,
});
export const playlistCache = new NoCache({
  fetchOneFn: (playlistId: string, signal) =>
    requestRetryPolicy.execute(
      (ctx) =>
        haloInfiniteClient.getPlaylist(playlistId, { signal: ctx.signal }),
      signal
    ),
});

export const [
  mapCache,
  gameVariantCache,
  playlistVersionCache,
  mapModePairCache,
] = (
  [
    {
      name: 'map',
      assetKind: AssetKind.Map,
    },
    {
      name: 'game variant',
      assetKind: AssetKind.UgcGameVariant,
    },
    {
      name: 'playlist',
      assetKind: AssetKind.Playlist,
    },
    {
      name: 'map mode pair',
      assetKind: AssetKind.MapModePair,
    },
  ] as const
).map(
  <const T extends keyof AssetKindTypeMap>({
    assetKind,
  }: {
    name: string;
    assetKind: T;
  }) =>
    new MemoryCache({
      keyTransformer: (key: Omit<AssetVersionLink, 'AssetKind'>) =>
        `${key.AssetId}.${key.VersionId}`,
      fetchOneFn: (key, signal) =>
        requestRetryPolicy.execute(
          (ctx) =>
            haloInfiniteClient
              .getSpecificAssetVersion(assetKind, key.AssetId, key.VersionId, {
                signal: ctx.signal,
              })
              .catch(() => {
                return { key, AssetKind: assetKind };
              }),
          signal
        ),
    })
) as [
  MemoryCache<
    AssetKindTypeMap[typeof AssetKind.Map] | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >,
  MemoryCache<
    AssetKindTypeMap[typeof AssetKind.UgcGameVariant] | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >,
  MemoryCache<
    AssetKindTypeMap[typeof AssetKind.Playlist] | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >,
  MemoryCache<
    AssetKindTypeMap[typeof AssetKind.MapModePair] | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >
];
const innerMatchPageCache = new MemoryCache({
  cacheExpirationMs: 15 * 1000,
  keyTransformer: (key: { start: number; xuid: string }) =>
    `${key.xuid}.${key.start}`,
  fetchOneFn: (key: { start: number; xuid: string }, signal) =>
    requestRetryPolicy.execute(
      (ctx) =>
        haloInfiniteClient.getPlayerMatches(
          key.xuid,
          MatchType.All,
          25,
          key.start,
          { signal: ctx.signal }
        ),
      signal
    ),
});
class MatchPageCache
  implements
    ICache<
      PlayerMatchHistory[],
      { start: number; xuid: string; pageSize: number }
    >
{
  get(
    key: { start: number; xuid: string; pageSize: number },
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]>;
  get(
    keys: { start: number; xuid: string; pageSize: number }[],
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]>[];
  get(
    keyOrKeys:
      | { start: number; xuid: string; pageSize: number }
      | { start: number; xuid: string; pageSize: number }[],
    signal?: AbortSignal | undefined
  ): Promise<PlayerMatchHistory[]> | Promise<PlayerMatchHistory[]>[] {
    return Array.isArray(keyOrKeys)
      ? keyOrKeys.map((k) => this.getOne(k, signal))
      : this.getOne(keyOrKeys, signal);
  }
  private async getOne(
    key: { start: number; xuid: string; pageSize: number },
    signal: AbortSignal | undefined
  ) {
    const startPage = Math.floor(key.start / 25);
    const endPage = Math.ceil((key.start + key.pageSize) / 25);
    const uncutPages = await Promise.all(
      Array.from({ length: endPage - startPage }, (_, i) =>
        innerMatchPageCache.get(
          {
            start: (startPage + i) * 25,
            xuid: key.xuid,
          },
          signal
        )
      )
    );
    const slicedPages = uncutPages
      .flat()
      .slice(
        key.start - startPage * 25,
        key.start - startPage * 25 + key.pageSize
      );
    return slicedPages;
  }
  set(): void {
    throw new Error('Method not implemented.');
  }
  delete(): void {
    throw new Error('Method not implemented.');
  }
}
export const matchPageCache = new MatchPageCache();
