'use client';
import {
  HaloAuthenticationClient,
  HaloInfiniteClient,
  RelyingParty,
  TokenPersister,
  XboxAuthenticationClient,
  XboxClient,
} from 'halo-infinite-api';
import { fetcher } from './fetcher';

let acquireToken: () => Promise<string>;
export let tokenPersister: Promise<TokenPersister> | TokenPersister;

if (typeof window === 'undefined') {
  acquireToken = () => new Promise<string>(() => {});
  tokenPersister = {
    load: () => null,
    save: () => {},
    clear: () => {},
  };
} else {
  acquireToken = async () => {
    const { acquireTokenSilent: acquireToken } = await import(
      '../msal-instances/client'
    );
    return acquireToken();
  };
  tokenPersister = import('../token-persisters/client').then(
    (m) => m.tokenPersister
  );
}
const xboxAuthClient = new XboxAuthenticationClient(tokenPersister, fetcher);
const haloAuthClient = new HaloAuthenticationClient(
  async () => {
    const xstsTicket = await xboxAuthClient.getXstsTicket(
      acquireToken,
      RelyingParty.Halo
    );
    return xstsTicket.Token;
  },
  () => xboxAuthClient.clearXstsTicket(RelyingParty.Halo),
  async () => {
    return await (await tokenPersister).load('halo.authToken');
  },
  async (token) => {
    await (await tokenPersister).save('halo.authToken', token);
  },
  async () => {
    await (await tokenPersister).clear('halo.authToken');
  },
  fetcher
);
export const getUserGamertag = async () => {
  const xstsTicket = await xboxAuthClient.getXstsTicket(
    acquireToken,
    RelyingParty.Xbox
  );
  return xstsTicket.DisplayClaims.xui[0].gtg;
};
export const haloInfiniteClient = new HaloInfiniteClient(
  haloAuthClient,
  fetcher
);
export const xboxClient = new XboxClient(
  {
    getXboxLiveV3Token: async () => {
      const xstsTicket = await xboxAuthClient.getXstsTicket(
        acquireToken,
        RelyingParty.Xbox
      );
      return xboxAuthClient.getXboxLiveV3Token(xstsTicket);
    },
    clearXboxLiveV3Token: () =>
      xboxAuthClient.clearXstsTicket(RelyingParty.Xbox),
  },
  fetcher
);
