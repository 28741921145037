import { createContext, useContext } from 'react';
import type {
  ILeaderboardProvider,
  LeaderboardEntry,
} from '../../lib/leaderboard';
import type { Observable } from 'rxjs';

export const LeaderboardContext = createContext<
  | (ILeaderboardProvider & { newEntries$: Observable<LeaderboardEntry[]> })
  | undefined
>(undefined);

export const useLeaderboard = () => useContext(LeaderboardContext);
