'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';

export const abortErrorCatch = (e: unknown) => {
  if (!(e instanceof DOMException && e.name === 'AbortError')) {
    throw e;
  }
};

export function useNavigationStartSignal() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const abortController = useMemo(
    () => new AbortController(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, searchParams]
  );
  useEffect(() => {
    function isAnchorOfCurrentUrl(currentUrl: string, newUrl: string) {
      const currentUrlObj = new URL(currentUrl);
      const newUrlObj = new URL(newUrl);
      // Compare hostname, pathname, and search parameters
      if (
        currentUrlObj.hostname === newUrlObj.hostname &&
        currentUrlObj.pathname === newUrlObj.pathname &&
        currentUrlObj.search === newUrlObj.search
      ) {
        // Check if the new URL is just an anchor of the current URL page
        const currentHash = currentUrlObj.hash;
        const newHash = newUrlObj.hash;
        return (
          currentHash !== newHash &&
          currentUrlObj.href.replace(currentHash, '') ===
            newUrlObj.href.replace(newHash, '')
        );
      }
      return false;
    }
    function findClosestAnchor(
      element: HTMLElement | null
    ): HTMLAnchorElement | null {
      while (element && element.tagName.toLowerCase() !== 'a') {
        element = element.parentElement;
      }
      return element as HTMLAnchorElement;
    }
    function handleClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const anchor = findClosestAnchor(target);
      const newUrl = anchor?.href;
      if (newUrl) {
        const currentUrl = window.location.href;
        const isExternalLink =
          (anchor as HTMLAnchorElement).target === '_blank';
        const isBlob = newUrl.startsWith('blob:');
        const isAnchor = isAnchorOfCurrentUrl(currentUrl, newUrl);
        if (
          !(
            newUrl === currentUrl ||
            isAnchor ||
            isExternalLink ||
            isBlob ||
            event.ctrlKey
          )
        ) {
          abortController.abort({ currentUrl, newUrl });
        }
      }
    }

    // Add the global click event listener
    document.addEventListener('click', handleClick);

    // Clean up the global click event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [abortController]);
  return abortController.signal;
}
